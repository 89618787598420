<template>
  <div class="color-picker px-2 py-2 border">
    <div class="d-flex gap-2">
      <b-form-input
        v-model="searchText"
        :placeholder="`Search in ${filteredColors.length} colors`"
        trim
        type="search"
      ></b-form-input>

      <b-button
        size="sm"
        variant="outline"
        @click="hasRecipe = !hasRecipe"
        class="py-0 d-flex items-center"
        :class="{ 'bg-black text-white': hasRecipe }"
      >
        <i class="material-icons">list_alt</i>
      </b-button>
    </div>

    <RecycleScroller
      class="mt-3"
      ref="scroller"
      :items="filteredColors"
      :item-size="itemSize"
      key-field="id"
      v-slot="{ item }"
    >
      <div :class="{ 'pr-2': filteredColors.length >= 8 }">
        <color-button
          :color="item"
          :active="activeColor.hex === item.hex"
          @click="onColor(item)"
        />
      </div>
    </RecycleScroller>
  </div>
</template>

<script>
import { findNearestColor } from '@/helpers';

const ColorButton = () => import('@/components/design/ColorButton.vue');

export default {
  name: 'ColorPicker',
  components: {
    ColorButton,
  },
  props: {
    picker: String,
    type: String,
    palette: String,
    colors: Array,
    activeColor: Object,
  },
  data() {
    return {
      itemSize: 88,
      searchText: '',
      hasRecipe: false,
    };
  },
  computed: {
    filteredColors() {
      return this.colors.filter((color) => {
        if (this.hasRecipe && color.type === 'wax' && !color.has_recipe_french) return false;
        if (color.type !== this.type) return false;
        if (this.palette === 'opaque' && color.palette !== 'opaque') return false;
        if (this.palette === 'transparent' && color.palette !== 'transparent') return false;
        if (this.palette === 'iris' && color.palette !== 'iris') return false;
        if (this.searchText.length) {
          const search = this.searchText.toLowerCase();
          const name = color.name.toLowerCase();
          const description = color.description.toLowerCase();
          const hex = color.hex.toLowerCase();
          if (!(name.includes(search) || description.includes(search) || hex.includes(search))) {
            return false;
          }
        }
        return true;
      });
    },
  },
  methods: {
    onColor(color) {
      this.$emit('input', color);
    },
    onActiveColor(prevColor) {
      const activeColorIndex = this.filteredColors
        .findIndex((filteredColor) => filteredColor.hex === this.activeColor.hex);
      if (activeColorIndex > -1) {
        this.scrollToColor(activeColorIndex);
        return;
      }

      if (prevColor) {
        this.searchText = '';
        this.$nextTick(() => {
          const nearestColorIndex = findNearestColor(
            prevColor.hex,
            this.filteredColors.map((c) => c.hex),
          );
          this.scrollToColor(nearestColorIndex, false);
        });
      }
    },
    scrollToColor(colorIndex, centerScroll) {
      centerScroll = centerScroll === undefined ? true : centerScroll;
      const scroller = this.$refs.scroller.$el;
      const scrollTop = centerScroll ? (
        colorIndex * this.itemSize
        - scroller.offsetHeight / 2
        + this.itemSize / 2
      ) : (colorIndex * this.itemSize);
      const shouldScroll = (
        scrollTop < scroller.scrollTop - scroller.offsetHeight / 2 + this.itemSize / 3
        || scrollTop > scroller.scrollTop + scroller.offsetHeight / 2 - this.itemSize / 3
      );
      if (shouldScroll) {
        this.$refs.scroller.scrollToPosition(scrollTop);
        setTimeout(() => {
          this.$refs.scroller.updateVisibleItems(false);
        }, 500);
      }
    },
    onResize() {
      const scroller = this.$refs.scroller.$el;
      const height = window.innerHeight - scroller.offsetTop;
      scroller.style.height = `${height}px`;
    },
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
    setTimeout(() => {
      this.onActiveColor();
    }, 500);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  watch: {
    activeColor: {
      handler(_, prevColor) {
        setTimeout(() => {
          this.onActiveColor(prevColor);
        }, 0);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .color-picker {
    width: 250px;
  }
</style>
